<template>
  <div class="horizontal-menu-wrapper">
    <b-navbar toggleable="sm" class="d-flex navbar-horizontal navbar-container navbar-light navbar-shadow menu-border" fixed="top">
      <b-navbar-toggle target="nav-collapse" class="sm-only">
        <MenuIcon class="d-flex"></MenuIcon>
      </b-navbar-toggle>
      <b-dropdown toggle-class="nav_profileBox" data-bs-toggle="dropdown" class="order-1 order-sm-2">
        <template #button-content>
          <img src="@/assets/images/custom/icon_profile.png" alt="" />
          <span>테스트</span>님
        </template>
        <b-dropdown-item class="dropdown-item"><i class="nav_log"></i>Log out</b-dropdown-item>
      </b-dropdown>
      <b-collapse id="nav-collapse" is-nav class="order-2 order-sm-1">
        <div class="pb-2 d-sm-block d-md-none"></div>
        <b-nav pills class="mb-0 flex-column flex-sm-row w-100">
          <b-nav-item to="/search-ad/bid/price" exact exact-active-class="active">검색광고 모니터링</b-nav-item>
          <b-nav-item to="/trend/word" exact exact-active-class="active">트렌드 모니터링</b-nav-item>
          <b-nav-item to="/report/keyword" exact exact-active-class="active">리포트 솔루션</b-nav-item>
        </b-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import { MenuIcon } from 'vue-feather-icons'

export default {
  data() {
    return {}
  },
  mounted() {},
  computed: {},
  methods: {},
  components: { MenuIcon }
}
</script>
