<template>
  <div class="horizontal-layout horizontal-menu navbar-sticky footer-static">
    <horizontal-nav-menu>
      <template #header="slotProps">
        <slot name="horizontal-menu-header" v-bind="slotProps" />
      </template>
    </horizontal-nav-menu>
    <transition :name="routerTransition" mode="out-in">
      <component :is="layoutContentRenderer" :key="$route.meta.navActiveLink ? $route.meta.navActiveLink : $route.name">
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </component>
    </transition>

    <slot name="customizer" />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import HorizontalNavMenu from './components/HorizontalNavMenu.vue'
import LayoutContentRendererHorizontalDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererHorizontalDefault.vue'

export default {
  components: {
    HorizontalNavMenu,
    LayoutContentRendererHorizontalDefault
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()
    return { routerTransition, contentWidth }
  },
  computed: {
    layoutContentRenderer() {
      return 'layout-content-renderer-horizontal-default'
    }
  }
}
</script>
