<template>
  <layout-horizontal>
    <router-view />
  </layout-horizontal>
</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal'

export default {
  components: {
    LayoutHorizontal
  }
}
</script>
